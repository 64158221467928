<template>
  <div class="search-filterheader">
    <template v-if="filters.length || sortings.length || results.length">
      <b-row class="filterheader-entry" align-v="center" align-h="between">
        <b-col v-show="$root.isDesktop" cols="auto">
          <h6 v-if="filters.length">{{ $t(`${tPath}.title`) }}</h6>
        </b-col>

        <b-col v-if="$slots.query" cols="12">
          <slot name="query" />
        </b-col>

        <b-col v-if="results.length" cols="auto">
          <slot name="resultcount" />
        </b-col>

        <b-col v-if="sortings.length && $slots.sorting" cols="auto">
          <slot name="sorting" />
        </b-col>
      </b-row>

      <sticky v-if="filters.length" :offsetTop="$root.header.inViewportHeight" :disabled="$root.isDesktop" class="filterheader-filters">
        <div class="filters-buttons d-flex flex-column">
          <div class="d-flex w-100 mb-2" v-if="!$root.isDesktop">
            <b-button class="filters-toggle flex-grow-1" variant="primary" size="sm" :block="!activeFilters.length" @click="$root.$emit('searchfiltermenu:toggle', true)"><lazy-icon icon="settings" /><span v-show="!activeFilters.length"> {{ $t(`${tPath}.toggle`) }}</span></b-button>
            <b-button-group class="pl-1">
              <b-button :variant="viewMode === 'grid' ? 'primary' : 'outline-primary'" :class="[{ 'bg-light': viewMode !== 'grid' }]" size="sm" @click="setViewMode('grid')"><lazy-icon icon="b-grid" /></b-button>
              <b-button :variant="viewMode === 'stack' ? 'primary' : 'outline-primary'" :class="[{ 'bg-light': viewMode !== 'stack' }]" size="sm" @click="setViewMode('stack')"><lazy-icon icon="b-view-stacked" /></b-button>
            </b-button-group>
          </div>

          <div v-show="activeFilters.length" class="filters-active">
            <dragger @dragger:drag="preventClick = true" @dragger:end="preventClick = false">
              <div class="btn-list">
                <b-button v-if="activeFilters.length > 1" variant="control" size="xs" @click="resetAllFilters">{{ $t(`${tPath}.resetall`) }} <lazy-icon icon="remove" /></b-button>

                <b-button v-for="filter in activeFilters" :key="filter.id" variant="control" size="xs" :title="filter.displayName" @click="resetFilter(filter.id)"><span><span class="font-regular">{{ filter.displayName }}:</span> {{ filter.controls.join(', ') }}</span><lazy-icon icon="remove" /></b-button>
              </div>
            </dragger>
          </div>
        </div>
      </sticky>
    </template>
  </div>
</template>

<script>
import Sticky from '@/components/private/Sticky'
import Dragger from '@/components/public/Dragger'

export default {
  name: 'SearchFilterHeader',
  components: {
    Sticky,
    Dragger
  },
  data() {
    return {
      preventClick: false
    }
  },
  computed: {
    filters() {
      return this.$store.getters['search/filters']
    },
    activeFilters() {
      return this.$store.getters['search/getActiveFilters']
    },
    sortings() {
      return this.$store.getters['search/sortings']
    },
    results() {
      return this.$store.getters['search/get']
    },
    viewMode() {
      return this.$store.getters['search/getViewMode']
    }
  },
  methods: {
    resetAllFilters() {
      if (!this.preventClick) this.$store.dispatch('search/resetFilters')
    },
    resetFilter(filterId) {
      if (!this.preventClick) this.$store.dispatch('search/resetFilter', { filterId })
    },
    setViewMode(viewMode) {
      this.$store.dispatch('search/setViewMode', { viewMode })
    }
  },
  watch: {
    activeFilters: {
      immediate: true,
      handler() {
        this.$root.$emit('dragger:reinit')
      }
    }
  }
}
</script>

<style lang="scss">
// mobile

$searchfilterheader-padding-y: $spacer !default;
$searchfilterheader-padding-x: 0 !default;
$searchfilterheader-bg: $beige !default;

$searchfilterheader-filters-buttons-gap: $spacer !default;
$searchfilterheader-filters-buttons-active-gap: $spacer * 0.4 !default;

// desktop

$searchfilterheader-desktop: $desktop-breakpoint !default;

$searchfilterheader-desktop-entry-gap: $spacer * 0.25 !default;

$searchfilterheader-desktop-filters-buttons-active-gap: $spacer * 0.5 !default;
$searchfilterheader-desktop-filters-buttons-active-padding-y: $spacer * 0.75 !default;
$searchfilterheader-desktop-filters-buttons-active-padding-x: $searchfilterheader-desktop-filters-buttons-active-padding-y !default;
$searchfilterheader-desktop-filters-buttons-active-bg: $beige !default;
$searchfilterheader-desktop-filters-buttons-active-border-radius: $border-radius !default;
$searchfilterheader-desktop-filters-buttons-active-btn-gap: $spacer * 0.5 !default;
$searchfilterheader-desktop-filters-buttons-active-btn-line-height: 1.2 !default;
$searchfilterheader-desktop-filters-buttons-active-btn-icon-gap: $spacer * 0.5 !default;

.search-filterheader {
  @include media-breakpoint-down(get-breakpoint-key($searchfilterheader-desktop, -1)) {
    min-height: 83px;

    @include fullwidth-bg();
    padding: $searchfilterheader-padding-y $searchfilterheader-padding-x;
    background-color: $searchfilterheader-bg;

    .filterheader-filters {
      .filters-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: $searchfilterheader-filters-buttons-gap;

        .filters-active {
          @include fullwidth-mobile();
          overflow: hidden;
          margin-left: 0;
          margin-right: 0;
          max-width: 100%;

          .btn-list {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0;
            padding-left: $searchfilterheader-filters-buttons-active-gap;

            &:first-child {
              padding-left: 0;
            }

            .btn,
            .button,
            button {
              margin-top: 0;
              margin-bottom: 0;
              white-space: nowrap;
              box-shadow: none;

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up($searchfilterheader-desktop) {
    .filterheader-entry {
      margin-bottom: $searchfilterheader-desktop-entry-gap;

      * {
        margin-bottom: 0;
      }
    }

    .filterheader-filters {
      .filters-buttons {
        .filters-toggle {
          display: none;
        }

        .filters-active {
          margin-bottom: $searchfilterheader-desktop-filters-buttons-active-gap;
          padding: $searchfilterheader-desktop-filters-buttons-active-padding-y $searchfilterheader-desktop-filters-buttons-active-padding-x;
          background-color: $searchfilterheader-desktop-filters-buttons-active-bg;
          border-radius: $searchfilterheader-desktop-filters-buttons-active-border-radius;

          .btn {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            line-height: $searchfilterheader-desktop-filters-buttons-active-btn-line-height;

            .bi {
              margin-left: $searchfilterheader-desktop-filters-buttons-active-btn-icon-gap;
            }
          }
        }
      }
    }
  }
}
</style>
