<template>
  <div class="search-resulttype-product" :class="[{ 'view-stacked': viewMode === 'stack', 'has-promobox': hasPromobox && !isLastPage }]">
    <div class="row row-cols-1 row-cols-md-3">
      <template v-for="(result, index) in results">
        <template v-if="hasPromobox && index === promoboxIndex">
          <slot name="promobox" />
        </template>
        <div class="col" :key="result.id">
          <c-product-teaser :href="$store.getters['gui/urls:getType']('product', result.urlSlug)" :imgSrc="result.image" :imgAlt="result.imageAlt || result.name" :productId="result.id" :stateId="result.deliveryStateId" :stockAmount="result.stockAmount" :restockTime="result.restockTime" :name="result.name" :model="result.model" :priceinfo="result.priceInfo" :categories="result.categories" :characteristics="result.characteristics" :colors="result.colors" :familyId="result.familyId" :gtmOptions="gtmOptions" :showColors="!showShoppingCart" :translationsPath="tPath" :resultsChanged="resultsChanged">
            <h6 v-if="result.model" class="text-uppercase">{{ result.model }}</h6>
            <p v-if="result.name">{{ result.name }}</p>

            <template v-slot:eyecatcher="">
              <c-eyecatcher v-for="(eyecatcher, eIndex) in result.eyecatchers" :key="eIndex" :variant="eyecatcher.variant" size="sm">{{
                eyecatcher.text
              }}</c-eyecatcher>
            </template>
            <template v-slot:shoppingcart="" v-if="showShoppingCart">
              <c-product-shoppingcart-button :productId="result.id" :small="true" :showCustomQuantity="false" :translations-once="true">
                <lazy-icon icon="cart" />
              </c-product-shoppingcart-button>
            </template>
          </c-product-teaser>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { COMPONENT_SEARCH_TYPES } from '@/constants';

export default {
  name: 'SearchResulttypeProduct',
  props: {
    searchType: {
      type: String,
      required: true
    },
    viewMode: {
      type: String,
      required: true
    },
    results: {
      type: Array,
      required: true
    },
    showShoppingCart: {
      type: Boolean,
      default: false
    },
    hasPromobox: {
      type: Boolean,
      default: false
    },
    promoboxIndex: {
      type: Number,
      default: 0
    },
    isLastPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      resultsChanged: false
    }
  },
  computed: {
    gtmOptions() {
      return COMPONENT_SEARCH_TYPES[this.searchType].gtm
    },
    cols() {
      return COMPONENT_SEARCH_TYPES[this.searchType].result.cols[this.$root.mobilefirstBreakpoint.key]
    }
  },
  watch: {
    results() {
      this.resultsChanged = true

      this.$nextTick(() => {
        this.resultsChanged = false
      })
    }
  }
}
</script>

<style lang="scss">
// mobile

$searchresulttypeproduct-productteaser-padding-top: $spacer * 0.5 !default;
$searchresulttypeproduct-productteaser-border: $card-border-width solid $card-border-color !default;

// tablet

$searchresulttypeproduct-tablet: $tablet-breakpoint !default;

.search-resulttype-product {
  @include media-breakpoint-down(get-breakpoint-key($searchresulttypeproduct-tablet, -1)) {
    border-top: $searchresulttypeproduct-productteaser-border;

    .product-teaser {
      padding-top: $searchresulttypeproduct-productteaser-padding-top;

      .card {
        border-top: 0 none;
        border-left: 0 none;
        border-right: 0 none;
        border-radius: 0;

        .card-body,
        .card-footer {
          padding-left: 0;
          padding-right: 0;
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  @include media-breakpoint-up($searchresulttypeproduct-tablet) {
    > .row {
      margin-top: $grid-gutter-width * -0.5;
      margin-bottom: $grid-gutter-width * -0.5;

      > .col {
        margin-top: $grid-gutter-width * 0.5;
        margin-bottom: $grid-gutter-width * 0.5;
      }
    }

    &.has-promobox {
      > .row {
        .col {
          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-down($searchresulttypeproduct-tablet) {
    &.view-stacked {
      border-top: 0;

      .row {
        .col {
          flex: 0 0 100%;
          max-width: 100%;

          margin-top: 5px;
          margin-bottom: 5px;

          .product-teaser {
            .card {
              display: grid;
              grid-template-columns: 33vw 1fr;
              grid-auto-rows: auto auto;
              column-gap: 8px;

              border-top: none;
              border-left: none;
              border-right: none;
              border-radius: 0;

              .card-img {
                grid-row: 1 / 3;
                grid-column: 1 / 2;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
              }

              .eyecatcher-stack {
                grid-row: 1 / 3;
                grid-column: 1 / 2;

                margin: 8px 8px 0;

                .eyecatcher.eyecatcher-sm {
                  width: 38px;
                  height: 38px;
                }
              }

              .card-body {
                grid-column: 2;

                padding-top: 0;
              }

              .card-footer {
                grid-column: 2;

                padding-bottom: 0;

                .product-wishlistbutton {
                  position: absolute;
                  bottom: 5px;
                  right: 5px;
                }
              }
            }
          }

          &:first-child {
            margin-top: 15px;
          }

          &:last-child {
            .product-teaser {
              .card {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
